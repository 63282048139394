import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
import PropTypes from "prop-types";
import { Button } from "antd";

import { ContentBlock, TextBlock } from "../../components/Blocks";
import Pages from "../../components/Pages";
import { formatURL, removeEmptyKeys, removePreloader } from "../../helpers";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { Share } from "../../widgets";
import { useThemeContext } from "../../components/Layout/ThemeContext";
import { withPageDataParams } from "../../containers/withPageData";
import { ALL_PROJECTS_NEWS } from "../../queries/queries.graphql";
import { ContentList } from "../../components/Lists";
import seoData from "../../components/SEO/data";
import { showMore } from "../../constants";

export const query = graphql`
	query projectsNews($pagePath: String!) {
		hasura {
			...AllProjectsNews
			...PageSettings
		}
	}
`;

function AllProjectsNewsPage({
	data,
	projectsNewsData: { loading: mediaLibraryLoading, error: mediaLibraryError, refetch },
}) {
	const { theme } = useThemeContext();

	function makeData(data = []) {
		return data.map(({ page_title_short, page_title_full, slug, category, main_image_preview, main_image }) => {
			const image = get(main_image_preview, "src", "") || get(main_image, "src", "");

			return {
				slug: formatURL(`news/${get(category, "slug", "")}/${slug}`),
				image,
				title: page_title_short || page_title_full,
			};
		});
	}

	const url = "news";
	const limit = 6;

	const [news, setNews] = useState(makeData(get(data, "hasura.v_serafim_projects_news", [])));
	const [loading, setLoading] = useState(true);
	const [offset, setOffset] = useState(6);
	const [disabled, setDisabled] = useState(false);
	const seo = get(data, "hasura.page_settings[0]", {});

	useEffect(() => {
		if (!mediaLibraryError && !mediaLibraryLoading) {
			setNews(news);
			setLoading(false);
		}

		removePreloader(!mediaLibraryLoading || mediaLibraryError);
	}, [mediaLibraryLoading]);

	async function handleLoad() {
		setLoading(true);
		const { data } = await refetch({ limit,
			offset });
		setLoading(false);
		const count = get(data, "v_serafim_projects_news_aggregate.aggregate.count", news.length);
		setNews([...news, ...makeData(get(data, "v_serafim_projects_news", []))]);
		setOffset(offset + 6);
		setDisabled(count === news.length);
	}

	return (
		<Pages url={url} entity={seo || get(seoData, "projects_news_page", {})}>
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs
						currentLabel={"Новости"}
						pageContext={
							{
								item: {
									title_full: "Гуманитарные проекты",
									slug: "serafim-project",
								},
							}
						}
					/>
				</div>
			</ContentBlock>
			<ContentBlock mount={news && news.length} key={"main-content"}>
				<div className={"container"}>
					<TextBlock title={"Все новости"} className={"pb-0"} />
					<ContentList items={news} className={"col-12 col-md-4"} style={{ width: "100%",
						height: "350px",
						objectFit: "cover" }}/>
					<div className="row justify-content-center mb-5">
						<Button
							disabled={loading || disabled}
							onClick={handleLoad}
							className={"d-noscript-none"}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}
						>
							{showMore}
						</Button>
					</div>
				</div>
			</ContentBlock>
			<Share url={url} pageTitleShort={"Все новости"} />
		</Pages>
	);
}

export default withPageDataParams(AllProjectsNewsPage, {
	body: ALL_PROJECTS_NEWS,
	name: "projectsNewsData",
	variables: { limit: 6,
		offset: 6 },
});

AllProjectsNewsPage.propTypes = {
	data: PropTypes.object,
	projectsNewsData: PropTypes.object,
};
